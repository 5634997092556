import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import MultipleUpload from "../../../../../components/inputs/UploadMultipleFile";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
const { Option } = Select;

const AddEditBuses = () => {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [applyLeavePopup, setApplyLeavePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectStudent, setSelectStudent] = useState();
  const [uploadAdmissionPopup, setUploadAdmissionPopup] = useState(false);
  const [parentFileList, setParentFileList] = useState([]);

  async function getFormList() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/bus/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }
  const formList = useQuery("/bus/", getFormList);

  const handleFileListChange = (fileList) => {
    setParentFileList(fileList);
  };
  async function fetchSubjects(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/bus/${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const busDetails = useQuery(
    ["bus", editData],
    () => editData && fetchSubjects(editData),
    {
      enabled: !!editData, // Only fetch if classIdData is truthy
    }
  );
  async function fetchIssues(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/bus/${id}/issue-logs`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const issueDetails = useQuery(
    ["issue-logs", editData],
    () => editData && fetchIssues(editData),
    {
      enabled: !!editData, // Only fetch if classIdData is truthy
    }
  );

  const columns = [
    // {
    //   title: "Name",
    //   dataIndex: [],
    //   key: "",
    //   align: "center",
    //   render: (record) => (
    //     <div
    //       key={record}
    //       style={{ color: "#111111" }}
    //       className="text-base text-center capitalize"
    //     >
    //       {record?.name}
    //     </div>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record) => (
        <div
          key={record.key}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {text}
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
            // icon={<SearchOutlined />}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Number",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.number}
          </div>
        );
      },
    },
    {
      title: "Occupancy",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, _, index) => {
        return (
          <div
            key={index}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.occupancy}
          </div>
        );
      },
    },
    {
      title: "Occupied",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center capitalize"
        >
          {record?.is_occupied ? "Yes" : "No"}
        </div>
      ),
    },
    {
      title: "Driver",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div className="text-base font-semibold text-center  cursor-pointer capitalize">
            {record?.route?.driver?.first_name
              ? `${record?.route?.driver?.first_name} ${record?.route?.driver?.last_name}`
              : "--"}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer text-blue-500 "
          onClick={() => {
            setIsEditMode(true);
            setEditData(record?._id);
            setApplyLeavePopup(true);
          }}
        >
          <span className="text-blue-500">Edit</span>
        </div>
      ),
    },
  ];
  const columnsLog = [
    {
      title: "Issue Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.date ? moment(record?.date).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      title: "History",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        return (
          <div
            key={record}
            style={{ color: "#111111" }}
            className="text-base text-center capitalize"
          >
            {record?.issue}
          </div>
        );
      },
    },
  ];

  const onFinish = (values) => {
    const payload = {
      number: values?.bus_number,
      name: values?.name,
      occupancy: values?.occupancy,
      registration_number: values?.registration_number,
      seats: Number(values.available_seats),
      issue_logs: values?.log_issue ? values?.log_issue : null,
      registered_year: moment(values?.registered_year).format("YYYY"),
      insurance_year: moment(values?.insurance_year).format("YYYY"),
      emission_year: moment(values?.emission_year).format("YYYY"),
      last_service_date: moment(values?.service_date).format("YYYY/MM/DD"),
      documents:
        parentFileList && parentFileList.length > 0 ? parentFileList : [],
    };
    if (payload) {
      if (isEditMode) {
        axios
          .put(`${serverUrl}/bus/${editData}`, payload, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          })
          .then((res) => {
            form.resetFields();
            setApplyLeavePopup(false);
            setEditData(null);
            setIsEditMode(false);
            queryClient.invalidateQueries("/bus/");
            queryClient.invalidateQueries("/issue-logs/");
            message.success(res?.data?.result?.message);
          })
          .catch((err) => {
            if (err?.response?.data?.error?.errors) {
              message.error(err?.response?.data?.error?.errors);
            }
          });
      } else {
        axios({
          method: "post",
          url: serverUrl + "/bus/",
          data: payload,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
          .then((res) => {
            queryClient.invalidateQueries("/bus/");
            queryClient.invalidateQueries("/issue-logs/");
            form.resetFields();
            setApplyLeavePopup(false);
            message.success(res?.data?.result?.message);
          })
          .catch((error) => {
            message.error(error?.response?.data?.error?.errors);
          });
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: busDetails?.data?.result?.data?.name,
      bus_number: busDetails?.data?.result?.data?.number,
      registration_number: busDetails?.data?.result?.data?.registration_number,
      occupancy: busDetails?.data?.result?.data?.occupancy,
      available_seats: busDetails?.data?.result?.data?.seats,
      registered_year: busDetails?.data?.result?.data?.registered_year
        ? moment(busDetails?.data?.result?.data?.registered_year, "YYYY")
        : null,
      insurance_year: busDetails?.data?.result?.data?.insurance_year
        ? moment(busDetails?.data?.result?.data?.insurance_year, "YYYY")
        : null,
      emission_year: busDetails?.data?.result?.data?.emission_year
        ? moment(busDetails?.data?.result?.data?.emission_year, "YYYY")
        : null,
      service_date: busDetails?.data?.result?.data?.last_service_date
        ? moment(
            busDetails?.data?.result?.data?.last_service_date,
            "YYYY/MM/DD"
          )
        : null,
      route: busDetails?.data?.result?.data?.route
        ? busDetails?.data?.result?.data?.route?.name
        : null,
    });
    if (Array.isArray(busDetails?.data?.result?.data?.documents)) {
      setParentFileList(busDetails?.data?.result?.data?.documents);
    }
  }, [isEditMode, busDetails?.data?.result]);

  return (
    <div>
      {" "}
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex justify-between items-center">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Buses
            </div>
            <div className="text-right pt-4">
              <Button
                type="primary"
                size="large"
                onClick={() => setApplyLeavePopup(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="py-5 table-overflow">
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                formList?.isSuccess &&
                formList?.data?.result?.data?.length > 0 &&
                formList?.data?.result?.data
                  ? formList?.data?.result?.data
                  : []
              }
              pagination={true}
              loading={formList?.isLoading}
            />
          </div>

          <Modal
            width={800}
            visible={applyLeavePopup}
            onOk={() => setApplyLeavePopup(false)}
            onCancel={() => {
              form.resetFields();
              setApplyLeavePopup(false);
              setEditData(null);
              setIsEditMode(false);
              setSelectStudent("");
              form.resetFields();
              setParentFileList(null);
            }}
            footer={null}
            forceRender
          >
            <h3 className="text-lg font-bold pb-3">
              {isEditMode ? "Update Bus" : " Add Bus"}
            </h3>
            <div className="bg-white rounded-2xl ">
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Bus Number"
                  name="bus_number"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Bus number is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {isEditMode && busDetails?.data?.result?.data?.route && (
                  <Form.Item label="Route Name" name="route" className="w-full">
                    <Input readOnly />
                  </Form.Item>
                )}
                <Form.Item name="name" label="Name" className="w-full">
                  <Input placeholder="name" />
                </Form.Item>
                <Form.Item
                  label="Registration Number"
                  name="registration_number"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Registration Number is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value > 13) {
                          return Promise.reject(
                            "Registration Number should be greater than 13 characters"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input maxLength={13} />
                </Form.Item>
                <Form.Item
                  label="Occupancy"
                  name="occupancy"
                  rules={[
                    {
                      required: true,
                      message: "Occupancy is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value < 1 || value > 100)) {
                          return Promise.reject(
                            "Occupancy should be between 1 and 100"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
                <Form.Item
                  label="Available seats"
                  name="available_seats"
                  rules={[
                    {
                      required: true,
                      message: "Available seats is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value < 1 || value > 100)) {
                          return Promise.reject(
                            "Available seats should be between 1 and 100"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>

                <Form.Item
                  label="Registered year"
                  name="registered_year"
                  rules={[
                    {
                      required: true,
                      message: "Registered year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    // disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Insurance year"
                  name="insurance_year"
                  rules={[
                    {
                      required: true,
                      message: "Insurance year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    // disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Emission year"
                  name="emission_year"
                  rules={[
                    {
                      required: true,
                      message: "Emission year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  label="Last service date"
                  name="service_date"
                  rules={[
                    {
                      required: true,
                      message: "Last service date is required",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="button"
                  className="text-white w-9"
                  onClick={() => setUploadAdmissionPopup(true)}
                >
                  Upload Document
                </Button>
                <div className="pt-4">
                  {parentFileList && parentFileList.length > 0 && (
                    <h5 className="pb-3 font-semibold text-sm">
                      Upload Preview
                    </h5>
                  )}
                  {parentFileList &&
                    parentFileList?.length > 0 &&
                    parentFileList?.map((data, index) => {
                      return (
                        <ui key={index}>
                          <li className="pb-2 capitalize">
                            {index + 1}.&nbsp;
                            {data?.files?.name}
                          </li>
                        </ui>
                      );
                    })}
                </div>
                <Form.Item
                  label="Log issue"
                  name="log_issue"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || value.length <= 100) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Description must be at most 100 characters"
                        );
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={3}
                    placeholder="Reason"
                    className="w-full border focus:outline-none resize-none"
                    maxLength={100} // Limit the input to 100 characters visually
                  />
                </Form.Item>

                {isEditMode && (
                  <div className="py-5 table-overflow">
                    <Table
                      rowKey="_id"
                      columns={columnsLog}
                      dataSource={
                        issueDetails?.isSuccess &&
                        issueDetails?.data?.result?.data?.length > 0 &&
                        issueDetails?.data?.result?.data
                          ? issueDetails?.data?.result?.data
                          : []
                      }
                      pagination={true}
                      loading={issueDetails?.isLoading}
                    />
                  </div>
                )}
                <div className="text-center">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    {isEditMode ? "Update" : " Submit"}
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <Modal
            width={800}
            visible={uploadAdmissionPopup}
            onOk={() => setUploadAdmissionPopup(false)}
            onCancel={() => {
              setUploadAdmissionPopup(false);
            }}
            footer={null}
          >
            <div>
              <MultipleUpload
                onFileListChange={handleFileListChange}
                parentResponse={parentFileList}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEditBuses;
