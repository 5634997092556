import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Avatar, Form, Image, Input, Modal, Row, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { cloudFrontUrl, serverUrl } from "../../nestserver";
import ImageUploadButton from "../inputs/ImageUploadButton";

/* -------------------------------------------------------------------------- */
/*                              Preview Componet                              */
/* -------------------------------------------------------------------------- */
function Preview({ data, onDelete, progress }) {
  return (
    <div
      style={{
        borderRadius: "8px",
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          borderRadius: "8px",
          height: "100%",
          width: "100%",
          opacity: 0.5,
        }}
      ></div>
      <Row
        justify="space-around"
        align="middle"
        style={{ height: "100%", width: "100%", padding: "10px" }}
      >
        <Button type="warning" icon={<EditOutlined />}>
          Change
        </Button>
      </Row>
    </div>
  );
}

function FeedSection({ data, enableDelete = false }) {
  const queryClient = useQueryClient();
  const { confirm } = Modal;
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState();
  const [form] = Form.useForm();

  const confirmDelete = () => {
    confirm({
      title: "Do you want to delete?",
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteAnnouncement();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteAnnouncement = () => {
    axios({
      method: "delete",
      url: serverUrl + "/announcement/" + data?._id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        message.success("Deleted");
        queryClient.invalidateQueries("announcement");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onAnnounce = (values) => {
    values["image"] = image;

    axios({
      method: "patch",
      url: serverUrl + "/announcement/" + data?._id,
      data: { ...values, content: values?.content?.trim() },
    })
      .then((res) => {
        message.success("Announcement Updated");
        form.resetFields();
        queryClient.invalidateQueries("announcement");
        setImage(false);
        setEdit(false);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  useEffect(() => {
    if (edit) {
      setImage(data?.image);
      form?.resetFields();
    }
  }, [data, edit]);

  return (
    <>
      <div className=" bg-white rounded-xl">
        {data && (
          <div className="py-5 px-5 ">
            <div className="flex  justify-between">
              <div
                style={{ color: "#616161" }}
                className="flex flex-row space-x-3 text-left text-lg px-5"
              >
                <div>
                  {/* <Avatar
                    size={50}
                    src={
                      data?.user
                        ? data?.user[
                            data?.user?.role === "instructor"
                              ? "teacher"
                              : data?.user?.role
                          ]?.profile_image
                          ? cloudFrontUrl +
                            "/" +
                            data?.user?._id +
                            "/" +
                            data?.user[
                              data?.user?.role === "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.profile_image
                          : "https://ui-avatars.com/api/?name=" +
                            data?.user[
                              data?.user?.role === "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.first_name +
                            " " +
                            data?.user[
                              data?.user?.role == "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.last_name
                        : "https://ui-avatars.com/api/?name=A"
                    }
                    alt="logo"
                  ></Avatar> */}
                  <Avatar
                    size={50}
                    src={
                      data?.user?.admin?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          data?.user._id +
                          "/" +
                          data.user?.admin?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          (data.user?.admin?.first_name +
                            " + " +
                            data.user?.admin?.last_name)
                    }
                    alt="logo"
                  ></Avatar>
                </div>
                <div className="flex flex-col">
                  <div style={{ color: "#09617E" }} className="text-xl">
                    {
                      data?.user[
                        data?.user?.role === "instructor"
                          ? "teacher"
                          : data?.user?.role
                      ]?.first_name
                    }
                  </div>
                  <div>
                    {moment(data?.updatedAt).format("MMM Do YYYY, h:mm a")}
                  </div>
                </div>
              </div>
              {enableDelete && (
                <div className="inline-flex space-x-3 text-lg">
                  <EditOutlined
                    onClick={() => setEdit(true)}
                    className=" text-gray-400 hover:text-yellow-500 cursor-pointer"
                  />
                  <DeleteOutlined
                    className=" text-gray-400 hover:text-red-500 cursor-pointer"
                    onClick={confirmDelete}
                  />
                </div>
              )}
            </div>

            <div className="text-lg text-left px-5 pt-4">
              <p
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {data?.content}
              </p>
              <div className="w-full py-4 ">
                <img
                  className="w-full object-cover rounded-lg"
                  src={
                    data?.image
                      ? cloudFrontUrl +
                        "/" +
                        data?.user?._id +
                        "/" +
                        data?.image
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        )}

        <Modal
          title="Edit Announcement"
          visible={edit}
          onCancel={() => setEdit(false)}
          okText="Save"
          onOk={() => form.submit()}
          afterClose={() => {
            form.resetFields();
            setImage(null);
          }}
        >
          <div className="bg-gray-100 mb-3">
            <ImageUploadButton
              folder={data?.user?._id}
              ratio={9 / 2.5}
              onUpload={(e) => setImage(e)}
            >
              <div>
                <Image
                  src={
                    image
                      ? cloudFrontUrl + "/" + data?.user?._id + "/" + image
                      : "https://via.placeholder.com/900x250/ddd/aaa?text=Add+Banner"
                  }
                  fallback={
                    "https://via.placeholder.com/900x250/ddd/aaa?text=Add+Banner"
                  }
                  preview={{
                    visible: false,
                    mask: <Preview />,
                  }}
                />
              </div>
            </ImageUploadButton>
            <div>
              <Button type="danger" onClick={() => setImage(null)}>
                Remove Image
              </Button>
            </div>
          </div>
          <div>
            <Form
              name="basic"
              initialValues={{ content: data?.content }}
              onFinish={onAnnounce}
              form={form}
            >
              <Form.Item
                name="content"
                rules={[
                  {
                    required: true,
                    message: "Please input the content",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Description"
                  className="w-full border focus:outline-none resize-none"
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default FeedSection;
